<template>
	<div>
		<el-dialog title="活动翼个性化定制平台 - 管理登录" :visible="true" width="400px" :show-close="false">
			<el-form :model="formData" :rules="rules" ref="form">
				<el-form-item prop="phoneNumber">
					<el-input
						placeholder="请输入手机号"
						v-model="formData.phoneNumber"
						maxlength="11"
					></el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input
						placeholder="请输入密码"
						v-model="formData.password"
						maxlength="30"
						show-password
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">登录</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import { Dialog, Form, FormItem, Input, Button, Message } from "element-ui";
import tokenManager from '../tokenManager'
export default {
	name: "Signin",
	components: {
		ElDialog: Dialog,
		ElForm: Form,
		ElFormItem: FormItem,
		ElInput: Input,
		ElButton: Button,
	},
	data() {
		return {
			formData: {
				phoneNumber: "",
				password: "",
			},
			rules: {
				phoneNumber: [
					{ required: true, message: "请输入手机号", trigger: "blur" },
					{
						pattern: /^1[0-9]{10}$/g,
						message: "请输入正确的手机号",
						trigger: "blur",
					},
				],
				password: [
					{ required: true, message: "请输入密码", trigger: "blur" },
					{
						min: 8,
						max: 30,
						message: "长度在 8 到 30 个字符",
						trigger: "blur",
					},
				],
			},
		};
	},
	methods: {
		async onSubmit(){
			
			this.$refs.form.validate(async (valid) => {
				if (!valid) {
					alert("请输入完整正确后再提交哦！");
					return false;
				}

				try{
					const {data} = await this.$api.manager.session.signin(this.formData.phoneNumber, this.formData.password)
					if (data.code === "OK") {
						Message.success('登录成功, 正在跳转...')
						tokenManager.set(data.data.token)
						this.$router.push({ name: "OrderChecking" });
					} else {
						Message.error("错误：" + data.errmsg);
					}
				} catch(e){
					Message.error("错误：" + e);
				}

			})
		}
	}
};
</script>

<style>
</style>